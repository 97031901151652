import logo from '../logo.svg';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';

function logout() {
    sessionStorage.clear();
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
    this.location.reload();
}

function Navigation(props) {
    const token = props.token;
    let dynamicDropDownLinks = null;


    if (token != null) {
        dynamicDropDownLinks = [[
            (<Link key="profile" to="/profile" state={{ pranker_token: token }}>Profile</Link>), (
                <Link key="sign-out" to="/" onClick={logout} >Sign out</Link>)]]
    } else {
        dynamicDropDownLinks = (
            <Link to="/login">Log in</Link>
        )
    }

    return (
        <div>
            <div className="navbar" style={{height:"80px", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.8)"}}>
                <Link to={{ pathname: "/", token: token }} className="logo">
                    <img src={logo} alt="Pod Rank logo" />
                </Link>
                {/* TODO: the nested links here is bad practice and we need a way to fix it */}
                <div className="navbar-links">
                    <Link to={{ pathname: "/about" }}>About</Link>
                    <Link to={{ pathname: "/", token: token }}>Home</Link>
                    {dynamicDropDownLinks}
                </div>
            </div>
        </div >
    );
}

export default Navigation;