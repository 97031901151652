import React from 'react';
import { Stack } from 'react-bootstrap';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer style={footerStyles}>
      <Stack direction="vertical">
          <div className="p-2" style={copywriteStyles}>
            &copy; {currentYear} Pod Rank, Inc.
          </div>
          <div className="p-2">
            <a href="https://instagram.com/pod.rank?igshid=MzRlODBiNWFlZA==" target="_blank" style={linkStyles}>
              Instagram
            </a>
          </div>
          <div className="p-2">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdlfQ4XWBhaqilFvH1hcHTHOmykAwqpWIhdCqJiIi9UxKONuw/viewform" target="_blank" style={linkStyles}>
              Give Feedback
            </a>
          </div>
          <div className="p-2">
            <a href="/terms_of_service_link" style={linkStyles}>
              Terms of Service
            </a>
          </div>
        </Stack>
    </footer>
  );
};

// Styling for the footer
const footerStyles = {
    backgroundColor: 'black', // Change background color to black
    padding: '20px',
    textAlign: 'center',
    bottom: '0',
  };
  
  const footerContainerStyles = {
    maxWidth: '1200px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
  
  const copywriteStyles = {
    fontSize: '15px',
    color: 'white', // Change text color to white 
  };
  
  const linksContainerStyles = {
    display: 'flex',
    gap: '20px',
  };
  
  const linkStyles = {
    color: 'white', // Change link text color to white
    fontSize: '15px',
    fontFamily: 'Gill Sans',
  };

export default Footer;
