import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom'
import ReactStars from "react-rating-stars-component";
import { Image, Spinner, Card } from 'react-bootstrap';
import { useAlert } from 'react-alert'
import AiDescription from './AiDescription';
import UserRatingCommentForm from "./UserRatingCommentForm"
import Wordcloud from './Wordcloud';
import RecommendationList from './RecommendationList'
import Timestamps from './Timestamps'
import InfoCarousel from './InfoCarousel';
import youtube_listen_logo from '../WatchonYouTube-white-1xPNG.png';

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function YoutubePodcastPage({authToken, episodeId, loadPodcastPage}) {
    const alert = useAlert()
    const [mainYoutubePodcastPageDetails, setMainYoutubePodcastPageDetails] = useState({
        podcast: null,
        userRating: null,
        userComment: '',
        avgRating: null,
        ratings: [],
        loading: true
    });
    const location = useLocation()
    // Note - episode slug could load incorrectly if not passed in and affect things
    // const { authToken } = location.state || {}
    // const { episodeId } = useParams();

    useEffect(() => {
        setMainYoutubePodcastPageDetails({
            podcast: null,
            userRating: null,
            userComment: '',
            avgRating: null,
            ratings: [], 
            loading: true
        })
        const fetchMainYoutubePodcastPageDetails = async () => {
            let securityLevel = authToken == null ? 'public' : 'private'
            let getPodcastUrl = new URL("/" + securityLevel + "/api/podcast/youtube/" + episodeId, PRANKER_SERVER_URL);
            let headers = {'Content-Type': 'application/json'}

            if (authToken != null){
                headers.Authorization = "Bearer "+ authToken
            }
            let podcastWithRating = await fetch(getPodcastUrl, {
                headers: headers
            }).then(
                (response) => {
                    if (!response.ok) {
                        throw new Error(`This is an HTTP error when calling ${getPodcastUrl}: The status is ${response.status}`);
                    }
                    return response.json();
            }).catch(
                (error) => {
                    console.log(error.message)
                    return null
            })

            let getAvgRatingUrl = new URL(`public/api/avgrating/youtube/${episodeId}`, PRANKER_SERVER_URL);
            let avgRating = await fetch(getAvgRatingUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`This is an HTTP error when calling ${getAvgRatingUrl}: The status is ${response.status}`);
                }
                return response.json();
            }).catch(
                (error) => {
                    console.log(error.message)
                    return null
            })

            if (podcastWithRating != null) {
                document.title = podcastWithRating.podcast.episode_title;
                setMainYoutubePodcastPageDetails({
                    podcast: podcastWithRating.podcast,
                    userRating: podcastWithRating.userRating !== null ? podcastWithRating.userRating.rating : null,
                    userComment: podcastWithRating.userRating !== null ? podcastWithRating.userRating.comment : '',
                    avgRating: avgRating,
                    ratings: podcastWithRating.ratings, 
                    loading: false
                })
            } else {
                setMainYoutubePodcastPageDetails({
                    podcast: null,
                    userRating: null,
                    userComment: '',
                    avgRating: null,
                    ratings: [], 
                    loading: false
                })

            }
        }
        
        fetchMainYoutubePodcastPageDetails()
    },[episodeId])



    if (mainYoutubePodcastPageDetails.loading) {
        return (
            <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
            </Spinner>
        );
    } else {
        if (mainYoutubePodcastPageDetails.podcast == null) {
            return <p>Error loading podcast</p>
        } 

        let starRating = null;
        if(mainYoutubePodcastPageDetails.avgRating != null && mainYoutubePodcastPageDetails.avgRating > 0) {
            starRating = <div >
                    <b>Average Rating:</b> 
                    <div className="centered-stars">
                        <ReactStars count={5} size={24} activeColor={'#5e17eb'} isHalf={true} value={mainYoutubePodcastPageDetails.avgRating} edit={false}/>
                    </div>
                </div>
        }

        let userFeedbackList = mainYoutubePodcastPageDetails.ratings.map(feedback => (
            <Card bg="dark" text="white" key={Date.now()}>
                <Card.Header className="centered-stars"><ReactStars activeColor={'#5e17eb'} half={true} count={5} value={feedback.rating} edit={false} /></Card.Header>
                <Card.Body>
                    <Card.Text>{feedback.comment}</Card.Text>
                </Card.Body>
            </Card>))

        return (
            <div>
                <h1>Podcast Episode: {mainYoutubePodcastPageDetails.podcast.episode_title}</h1>
                <h2>Podcast Series: {mainYoutubePodcastPageDetails.podcast.episode_series} </h2>
                <br/>
                <div>
                    <div style={containerStyle}>
                    <Image style={imageStyle} src={mainYoutubePodcastPageDetails.podcast.image_url} />
                    </div>
                    <br/>
                    {starRating}
                    <AiDescription
                        podcastEpisodeId={episodeId}
                        isParentLoading={mainYoutubePodcastPageDetails.loading}
                        podcastType="youtube"
                        defaultDescription={mainYoutubePodcastPageDetails.podcast.html_description}
                    />
                    <br/>

                    <div>
                        <Wordcloud 
                            authToken={authToken}
                            episodeId={episodeId}
                            podcastType="youtube"
                            isParentLoading={mainYoutubePodcastPageDetails.loading}
                        />
                        <br/>
                        <Timestamps
                            authToken={authToken}
                            podcastEpisodeId={episodeId}
                            podcastType="youtube"
                            isParentLoading={mainYoutubePodcastPageDetails.loading}
                        />

                    </div>

                    <br/>
                    <UserRatingCommentForm 
                        userRating={mainYoutubePodcastPageDetails.userRating} 
                        userComment={mainYoutubePodcastPageDetails.userComment} 
                        authToken={authToken}
                        episodeId={episodeId}
                        isParentLoading={mainYoutubePodcastPageDetails.loading}
                    />
                    <br/>
                </div>

                <a href={mainYoutubePodcastPageDetails.podcast.view_url} target="_blank" rel="noopener noreferrer">
                    <img src={youtube_listen_logo} alt="YouTube listen logo"/>
                </a>
                <br/>
                <br/>
                <InfoCarousel
                authToken={authToken} 
                />

                <RecommendationList authToken={authToken} podcastEpisodeId={episodeId} podcastType="youtube" loadPodcastPage={loadPodcastPage} isParentLoading={mainYoutubePodcastPageDetails.loading}/>
                <br/>
                <br/>
            </div>
        );
    }
}

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',  
  };


const imageStyle = {
    width: '340px',  
  height: '300px', 
  borderRadius: '15px', 
  boxShadow: '0 40px 80px rgba(0, 0, 0, 0.3)', 
  };

export default YoutubePodcastPage;
