import React from 'react';

import ProductDisplay from './ProductDisplay';
import SuccessDisplay from './SuccessDisplay';

export default function StripePage(props) {
      if (props.user.customer_status === "active") {
        // if status is active then there should ALWAYS be a customer id in the table
        return <SuccessDisplay pranker_token={props.pranker_token} customer_id={props.user.customer_id} />;
      } else {
        return <ProductDisplay pranker_token={props.pranker_token}/>;
      }
    
}