import React, { useState, useEffect} from 'react';
import { useAlert } from 'react-alert'
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function KeyWords({podcastEpisodeId, isParentLoading, podcastType}) {
    const alert = useAlert()
    const [keyWordDetails, setKeyWordDetails] = useState({
      keywords: [],
      pageLoading: false
    });

    useEffect(() => {
      if (isParentLoading) {
        return;
      }
      setKeyWordDetails({
        ...keyWordDetails, pageLoading: true
      })
      const fetchKeyWords = async () => {
        let keyWordsUrl = new URL(`/public/api/ai/categories/${podcastType}/${podcastEpisodeId}`, PRANKER_SERVER_URL);
        // the response is currently a list of strings
        let keyWordsResponse = await fetch(keyWordsUrl)
          .then(
            (response) => {
              if (!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`);
              }
              return response.json();
          }).catch((err) => {
            console.log("Could not generate keywords");
            return null;
          })

        let keyWords = []
        if (keyWordsResponse != null && keyWordsResponse.length > 0) {
          keyWords = keyWordsResponse.map(keyWord => {
            return <Badge bg="primary" key={keyWord}>{keyWord}</Badge>
        })
        }
        setKeyWordDetails({
          keywords: keyWords,
          pageLoading: false
        })
      }
      fetchKeyWords()
    },[isParentLoading])
    
    if (keyWordDetails.pageLoading || keyWordDetails.keywords.length == 0) {
      return null;
    } else {
      return (
        <div>
          <Stack direction="horizontal" gap={2} className="centered-stars">
            {keyWordDetails.keywords}
          </Stack>
        </div>
      );
    }
}

export default KeyWords;
