

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

const DESKTOP_YOUTUBE_VIDEO_REGEX = /^(https\:\/\/www\.youtube\.com\/watch\?v=){1}.+/;
const MOBILE_YOUTUBE_VIDEO_REGEX = /^(https\:\/\/youtu\.be\/.+){1}/;
const SPOTIFY_EPISODE_REGEX = /^(https\:\/\/open\.spotify\.com\/episode\/.+){1}/;

export const getEpisodeSlug = (episodeTitle) => {
    return episodeTitle.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '-');
}

export const getPodcastUrlFromSearchQuery = (searchQuery) => {
    let podcastId = null
    if (isDesktopYoutubeUrl(searchQuery)) {
      podcastId = getPodcastIdFromDesktopYoutubeVideoUrl(searchQuery)
      return "/podcast/youtube/" + podcastId;
    } else if (isMobileYoutubeUrl(searchQuery)) {
      podcastId = getPodcastIdFromEndOfPathPodcastUrl(searchQuery);
      return "/podcast/youtube/" + podcastId;
    } else if (isSpotifyEpisodeUrl(searchQuery)) {
      podcastId = getPodcastIdFromEndOfPathPodcastUrl(searchQuery);
      return "/podcast/spotify/" + podcastId;
    } else {
      return null;
    }
  }

  const getPodcastIdFromDesktopYoutubeVideoUrl = (desktopYoutubeUrl) => {
    let urlSearchParamsStart = desktopYoutubeUrl.indexOf('?')
    let urlSearchParams = desktopYoutubeUrl.substring(urlSearchParamsStart)
    return (new URLSearchParams(urlSearchParams)).get('v');
  }
  
  const getPodcastIdFromEndOfPathPodcastUrl = (endOfPathPodcastUrl) => {
    const url = new URL(endOfPathPodcastUrl);
    const indexOfLastUrlSlash = url.pathname.lastIndexOf('/')
    return url.pathname.substring(indexOfLastUrlSlash + 1)
  }
  
  const isDesktopYoutubeUrl = (searchQuery) => {
    return DESKTOP_YOUTUBE_VIDEO_REGEX.test(searchQuery);
  }
  
  const isMobileYoutubeUrl = (searchQuery) => {
    return MOBILE_YOUTUBE_VIDEO_REGEX.test(searchQuery);
  }
  
  const isSpotifyEpisodeUrl = (searchQuery) => {
    return SPOTIFY_EPISODE_REGEX.test(searchQuery);
  }