import React, { useState, useEffect} from 'react';
import { useAlert } from 'react-alert'
import { useNavigate } from "react-router";
import { Button } from 'react-bootstrap';

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function Timestamps({authToken, podcastEpisodeId, podcastType, isParentLoading}) {
    const navigate = useNavigate()
    const [timestampDetails, setTimestampDetails] = useState({
      topTimestamps: []
    });

    useEffect(() => {
      if (authToken == null || isParentLoading) {
        return;
      }
      const fetchTimestamps = async () => {
        let timestampsUrl = new URL(`private/api/timestamps/${podcastType}/${podcastEpisodeId}`, PRANKER_SERVER_URL);
        let headers = {'Content-Type': 'application/json', Authorization: "Bearer "+ authToken}
        let timestampsResponse = await fetch(timestampsUrl, {
          headers: headers
        })
          .then(
            (response) => {
              if (!response.ok) {
                console.log(`This is an HTTP error: The status is ${response.status}`);
                throw new Error(`This is an HTTP error: The status is ${response.status}`);
              }
              return response.json();
          })

          setTimestampDetails({
            topTimestamps: timestampsResponse.topTimestamps
          })
      }
      fetchTimestamps()
    },[isParentLoading])
    
    if (authToken == null) {
      return <div>
          <Button variant="secondary" onClick={() => navigate("/login")}>
              Get Timestamps
          </Button>
      </div>;
    }
    let commentTimeStamps = timestampDetails.topTimestamps.map(
      (object, i) => <li key={i}>{object.timestamp}: {object.postContext}</li>)
    let timestampBlock = null

    if (timestampDetails.topTimestamps.length > 0 || timestampDetails.topTimestamps.length > 0) {
      timestampBlock = <div style={{ maxHeight: 100, overflow: 'auto'}} >
        <h4>Comment Timestamps</h4>
        <ul>{commentTimeStamps}</ul>
      </div>
    } else {
      return null;
    }
    
    return (
        <div>
          {timestampBlock}
        </div>
    );
}

export default Timestamps;
