import React from 'react';
import { Button, Card} from 'react-bootstrap';
import {getEpisodeSlug} from '../utils/podcast_episode_utils'
import { useNavigate} from "react-router";
import PodcastTypes from '../types/podcast_types'
import logo from '../logo.svg';
import spotify_logo from '../Spotify_Logo_CMYK_Green.png';
import youtube_logo from '../yt_logo_rgb_light.png';

function PodcastCard({auth_token, podcast_type, podcast_id, episode_title, episode_series, image_url, loadPodcastPage}) {
    let episode_slug = getEpisodeSlug(episode_title)
    const navigate = useNavigate();

    function handleClick() {
        let podcastUrl = "/podcast/" + podcast_type + "/" + podcast_id + "/" + episode_slug
        navigate(podcastUrl, {state: { authToken: auth_token, episode_slug: episode_slug}});
        if (loadPodcastPage != null) {
            loadPodcastPage(podcast_type, podcast_id)
        }
    }

    let border_color = null;
    let podcast_card_logo = logo
    if (podcast_type.toLowerCase() == PodcastTypes.SPOTIFY) {
        border_color = "success"
        podcast_card_logo = spotify_logo
    } else if (podcast_type.toLowerCase() == PodcastTypes.YOUTUBE) {
        border_color = "danger"
        podcast_card_logo = youtube_logo
    } else {
        console.log(`Received unexpected podcast type when getting border color for podcast card: ${podcast_type}.`);
    }

    return (
        <div style={{"padding": "10px"}}>
            <Card className='podcast-card-size' border={border_color} onClick={handleClick} style={{"boxShadow": '10px 5px 10px rgba(0, 0, 0, 0.3)', "borderRadius": "15px"}}>
                <div style={{"justifyContent": "center", "margin": "4%"}}>
                    <img src={podcast_card_logo}  width="30%" alt="Pod Rank Card logo"/>
                </div>
                <Card.Img variant="top" src={image_url}/>
                <Card.Body>
                    <Card.Title style={{"justifyContent": "center", "maxHeight": "6rem", "overflowY": "scroll"}}>{episode_title} {episode_series}</Card.Title>
                    <Button variant="secondary" onClick={handleClick}>
                    Get Insights
                    </Button>
                </Card.Body>
            </Card>
            <br/>
        </div>
    );
}

export default PodcastCard;