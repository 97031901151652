import React, { useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert'
const { REACT_APP_PRANKER_SERVER } = process.env;

export default function Register() {
    const navigate = useNavigate();
    const alert = useAlert()

    const [username, setUsername] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const registerUser = async e => {
        e.preventDefault();
        if(password == '') {
            alert.error('password is empty')
            return;
        }
        if (password != confirmPassword ) {
            alert.error('password not matching confirmation password')
            return;
        }
        fetch(REACT_APP_PRANKER_SERVER + `/api/pranker`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                phoneNum: phoneNum,
                email: email,
                password: password
            })}
        )
        .then(
            (result) => {
                if (result.ok) {
                    alert.success('successfully registered. please log in now :)')
                    navigate('/login')
                } else {
                    alert.error('something went wrong registering your user')
                }
            },
            (error) => {
                alert.error('something went wrong registering your user')
            }
        )
    }

    const validateAndSetUsername = (event) => {
        setUsername(event.target.value)
    }

    const validateAndSetEmail = (event) => {
        setEmail(event.target.value)
    }

    const validateAndSetPhoneNum = (event) => {
        setPhoneNum(event.target.value)
    }

    const validateAndSetPassword = (event) => {
        setPassword(event.target.value)
    }

    const validateAndSetConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
    }
  

  return (
    <div>
        <br/>
            <h1>Please Register</h1>
            <br/>
        <Form className='registerForm' onSubmit={registerUser}>
            <Form.Group className="mb-2" controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control required size="sm" type="text" placeholder="Enter Username" value={username} onChange={validateAndSetUsername} style={fieldStyle}/>
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control required size="sm" type="email" placeholder="Enter email" value={email} onChange={validateAndSetEmail} style={fieldStyle}/>
            </Form.Group>
            <Form.Group className="mb-2" controlId="formPhoneNum">
                <Form.Label>Phone Num</Form.Label>
                <Form.Control required  size="sm" type="tel" placeholder="Enter phone number" value={phoneNum} onChange={validateAndSetPhoneNum} style={fieldStyle}/>
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicPassword">
                <Form.Label column>Password</Form.Label>
                <Form.Control required size="sm" type="password" placeholder="Password" value={password} onChange={validateAndSetPassword} style={fieldStyle}/>
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicPasswordConfirmation">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control required size="sm" type="password" placeholder="Confirm Password" value={confirmPassword} onChange={validateAndSetConfirmPassword} style={fieldStyle}/>
            </Form.Group>
            <Button variant="primary" type="submit">Submit</Button>
        </Form>
    </div>
  )
}

const fieldStyle = {
    width: "60%", 
    margin: "auto",
    borderStyle: "outset",
    //borderColor: "#5E17EB",
}