import React, { useState, useEffect} from 'react';
import ReactStars from "react-rating-stars-component";
import { Button, Form } from 'react-bootstrap';
import { useAlert } from 'react-alert'
import { useNavigate } from "react-router";

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

export default function UserRatingCommentForm({userRating, userComment, authToken, episodeId, isParentLoading}) {
    const navigate = useNavigate();
    const alert = useAlert()
    const [userPodcastFeedback, setUserPodcastFeedback] = useState({
        userRating: 0,
        userComment: ''
    })
    
    useEffect(() => {
        setUserPodcastFeedback({
            userRating: userRating,
            userComment: userComment
        })
    },[isParentLoading])

    const addRatingOrComment = () => {
        if (authToken == null) {
            alert.error('Please log in to add your rating or comment.')
        } else {
            let addRatingOrCommentRequest = new URL(`private/api/ratings`, PRANKER_SERVER_URL);
            fetch(addRatingOrCommentRequest, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer "+ authToken
                },
                body: JSON.stringify({
                    podId: episodeId,
                    rating: userPodcastFeedback.userRating,
                    comment: userPodcastFeedback.userComment
                })}
            )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`This is an HTTP error: The status is ${response.status}`);
                }
                return response.json();
            }).then((result) => {
                setUserPodcastFeedback({
                    userRating: result.rating,
                    userComment: result.comment
                })
                alert.success('successfully added your rating/comment!')
                //TODO Handle comments  
            }).catch((err) => {
                console.log(err.message);
                alert.error('something went wrong adding your rating/comment')
            });
        }
    }
    if(authToken == null) {
        return <div>
            <Button variant="secondary" onClick={() => navigate("/login")}>
                Add Your Rating & Opinion
            </Button>
        </div>;
    }
    let rating = 0;
    if (userPodcastFeedback.userRating != null) {
        rating = userPodcastFeedback.userRating;
    }
    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
        <Form onSubmit={(e) => { e.preventDefault(); addRatingOrComment(); }}>
          <b>What was your opinion of this podcast?</b>
          <h4>Your Rating:</h4>
          <div className="centered-stars">
            <ReactStars count={5} size={24} activeColor={'#5e17eb'} isHalf={true} value={userPodcastFeedback.userRating} edit={true} onChange={e => setUserPodcastFeedback({ ...userPodcastFeedback, userRating: e })} />
          </div>
          <h4>Your Opinion:</h4>
          <Form.Control type="text" onChange={e => setUserPodcastFeedback({ ...userPodcastFeedback, userComment: e.target.value })} value={userPodcastFeedback.userComment} style={{ padding: '20px', width: '70%', height: '70px', margin: '0 auto', boxShadow: '0 0 20px rgba(0, 0, 0, 0.4)', fontSize: '90%' }} />
          <Button variant="primary" type="submit" style={{ marginTop: '20px' }}>
            Submit
          </Button>
        </Form>
      </div>
      
    );
}
