import React, { useEffect } from 'react';
import './styles/about-card.css';
import MediumFeed from './MediumFeed';
function About(props) {

    useEffect(() => {
        document.title = "About Pod Rank";
    }, []);

    return (
        <div>
            <h1>Our Vision at Pod Rank is:</h1>
            <p>
                To save millions of podcast listeners time by giving accurate descriptions, community opinions, 
                word clouds, related topics, and timestamps about the podcast before they press play.
            </p>
            <br />
            <h1>Our Values at Pod Rank are</h1>
            <b>Listener Empowerment:</b> Our commitment to putting listeners first drives us to empower you with the information and tools you need to make the most of your podcast experience.
            <br/>
            <b>Resourceful Tenacity:</b> We embrace a resourceful spirit that empowers us to overcome challenges, find innovative solutions, and relentlessly pursue our mission for podcast listeners.
            <br/>
            <b>Inclusivity:</b> We value and embrace inclusivity, fostering a diverse and welcoming community of podcast enthusiasts.
            <br/>
            <b>Open Discourse:</b> We value open discourse, where everyone's opinions are not only welcomed but encouraged, fostering a diverse and vibrant exchange of ideas.
            <br/>
            <br/>
            <MediumFeed/>

        </div>
    );
}

  


export default About;
