
import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert'
import { useLocation, useNavigate } from "react-router";
import Cookies from 'js-cookie';

import './styles/Login.css';

const { REACT_APP_PRANKER_SERVER } = process.env;

async function loginUser(credentials) {
    return fetch(REACT_APP_PRANKER_SERVER + `/api/login?username=`+credentials.username + "&password=" + credentials.password)
        .then(res => res.json())
        .then(
            (result) => {
                var expiryDate = new Date(parseInt(result.expiryTime));
                Cookies.set('accessToken', result.accessToken, { expires: expiryDate })
                Cookies.set('refreshToken', result.refreshToken, { expires: expiryDate })
              return result;
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              return null;
            }
          )
}

export default function Login({ setToken }) {
    const alert = useAlert()
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    const handleSubmit = async e => {
        e.preventDefault();
        const tokens = await loginUser({
            username,
            password
        });

        if (tokens == null) { 
            alert.error('error logging in!')
        } else {
            setToken(tokens.accessToken);
            alert.success('successful login')
            navigate(-1)
        }
    }
    
    return (
        <div>
            <br/>
            <h1>Please Log In</h1>
            <br/>
            <Form className='registerForm' onSubmit={handleSubmit}>
                <Form.Group className="mb-2" controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control required style={{width: "60%", margin: "auto", borderStyle: "outset"}} size="sm" type="text" placeholder="Enter Username" onChange={e => setUserName(e.target.value)}/>
                </Form.Group>
                <Form.Group className="mb-2" controlId="formBasicPassword">
                    <Form.Label column>Password</Form.Label>
                    <Form.Control required style={{width: "60%", margin: "auto" , borderStyle: "outset"}} size="sm" type="password" placeholder="Password"  onChange={e => setPassword(e.target.value)}/>
                </Form.Group>
                <br/>
                <div className="d-grid gap-2">
                    <Button className="v1-login-button" variant="primary"  style={{width: "40%", margin: "auto", backgroundColor: "#5e17eb"}} type="submit">Submit</Button>
                    <Button className="v1-register-button" variant="outline-primary"  style={{width: "40%", margin: "auto", color: "#5e17eb", borderColor: "#5e17eb"}} href="/register">Register</Button>
                </div>
            </Form>
            <br/>
        </div>
    );

}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }
