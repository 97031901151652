import React, { useState, useEffect} from 'react';
import { useAlert } from 'react-alert'

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function DescriptionSpeech({podcastEpisodeId, isParentLoading, podcastType}) {
    const alert = useAlert()
    const [descriptionSpeechDetails, setDescriptionSpeechDetails] = useState({
      descriptionSpeech: null,
      pageLoading: false
    });

    useEffect(() => {
      if (isParentLoading) {
        return;
      }
      setDescriptionSpeechDetails({
        ...descriptionSpeechDetails, pageLoading: true
      })
      const fetchDescriptionSpeech = async () => {
        let descriptionSpeechUrl = new URL(`/public/api/podcast/audio-description/${podcastType}/${podcastEpisodeId}`, PRANKER_SERVER_URL);
        // the response is currently a blob
        let descriptionSpeechResponse = await fetch(descriptionSpeechUrl, {
          method: 'GET',
          responseType: 'blob',
        })
          .then(
            (response) => {
              if (!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`);
              }
              return response.arrayBuffer();
          }).catch((err) => {
            console.log("Could not generate descriptionSpeech");
            return null;
          })
          // Create a Blob object from the binary audio data
        const audioBlob = new Blob([descriptionSpeechResponse], { type: 'audio/mpeg' });
        setDescriptionSpeechDetails({
          descriptionSpeech: audioBlob,
          pageLoading: false
        })
      }
      fetchDescriptionSpeech()
    },[isParentLoading])
    
    if (descriptionSpeechDetails.pageLoading || descriptionSpeechDetails.descriptionSpeech == null) {
      return null;
    } else {
      const blobUrl = URL.createObjectURL(descriptionSpeechDetails.descriptionSpeech);
      return (
        <div>
          <audio controls>
            <source src={blobUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      );
    }
}

export default DescriptionSpeech;
