import React, { useState, useEffect} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom'
import { useParams } from "react-router-dom";
import YoutubePodcastPage from "./YoutubePodcastPage"
import SpotifyPodcastPage from "./SpotifyPodcastPage"
import PodcastList from "./PodcastList"
import PodcastTypes from '../types/podcast_types'
import InfoCarousel from './InfoCarousel';


function PodcastPage({authToken}) {
    const { episodeId, podcastType } = useParams();
    const [podcastPageDetails, setPodcastPageDetails] = useState({
        podcastType: podcastType,
        episodeId: episodeId,
        authToken: authToken
    });
    const location = useLocation()
    // Note - episode slug could load incorrectly if not passed in and affect things
    // const { authToken, episode_slug } = location.state || {}
    
    function loadPodcastPage(podcast_type, episode_id) {
        // scroll to top of screen
        setPodcastPageDetails({
            ...podcastPageDetails, 
            podcastType: podcast_type,
            episodeId: episode_id
        })
        window.scrollTo(0, 0)
    }

    let podcastPage = null;

    if (podcastPageDetails.podcastType.toLowerCase() == PodcastTypes.SPOTIFY) {
        podcastPage = <SpotifyPodcastPage
            authToken={podcastPageDetails.authToken}
            episodeId={podcastPageDetails.episodeId}
            loadPodcastPage={loadPodcastPage}
        />
    } else if (podcastPageDetails.podcastType.toLowerCase() == PodcastTypes.YOUTUBE) {
        podcastPage = <YoutubePodcastPage
            authToken={podcastPageDetails.authToken}
            episodeId={podcastPageDetails.episodeId}
            loadPodcastPage={loadPodcastPage}
        />
    } else {
        console.log(`Received unexpected podcast type when loading podcast page: ${podcastPageDetails.podcastType.toLowerCase()}.`);
    }

    return <div>
        {podcastPage}
        <PodcastList
        type="trwd"
        authToken={podcastPageDetails.authToken}
        loadPodcastPage={loadPodcastPage}
        isParentLoading={false}
        />

        <PodcastList
        type="mruwd"
        authToken={podcastPageDetails.authToken}
        loadPodcastPage={loadPodcastPage}
        isParentLoading={false}
        />
        
        <br />
        <InfoCarousel
            authToken={podcastPageDetails.authToken}
        />
        <br />
    </div>
}

export default PodcastPage;
