import React from 'react';
import { Button } from 'react-bootstrap';
import '../App.css';
import logo from '../logo.svg';


const { REACT_APP_PRANKER_SERVER } = process.env;
const { PRICE_LOOKUP_KEY } = process.env

export default function ProductDisplay (props) {

    const getCheckoutSession = (event) => {
        event.preventDefault();
        fetch(
            REACT_APP_PRANKER_SERVER + `/api/create-checkout-session`,
            {
                method: 'POST',
                headers: {
                    Authorization: "Bearer "+ props.pranker_token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    lookup_key: PRICE_LOOKUP_KEY
                })
            }
        )
        .then(res => res.json())
        .then(
            (res) => {
                window.open(res.checkout_url, "_self");
            },
            (error) => {
                console.log("error checking out, please try again later! " + error)
            }
        )
    }

    return (
        <section>
            <div className="product">
                <img src={logo} alt="Pod Rank logo"/>
                <div className="description">
                    <h3>Pod Rank Premium Subscription</h3>
                    <h5>$1.99 / month</h5>
                </div>
            </div>

            <Button variant="primary" onClick={getCheckoutSession}>Checkout</Button>
        </section>
)};