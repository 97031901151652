import React, { useState, useEffect} from 'react';
import { useAlert } from 'react-alert'

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function MediumFeed({podcastEpisodeId, isParentLoading, podcastType}) {
    const alert = useAlert()

    const [mediumFeedDetails, setMediumFeedDetails] = useState({
      articles: [],
      pageLoading: false
    });

    useEffect(() => {
      if (isParentLoading) {
        return;
      }
      setMediumFeedDetails({
        ...mediumFeedDetails, pageLoading: true
      })
      const fetchMediumFeed = async () => {

        let mediumFeedUrl = new URL(`/public/api/medium/stories`, PRANKER_SERVER_URL);
        let mediumFeedResponse = await fetch(mediumFeedUrl)
          .then(
            (response) => {
              if (!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`);
              }
              return response.json();
            }).catch(function(err) {  
                console.log('Failed to fetch Medium Articles');  
            });

        let mediumArticles = []

        if (mediumFeedResponse != null && mediumFeedResponse.length > 0) {
          mediumArticles = mediumFeedResponse.map(mediumArticle => {
              return <div key={mediumArticle.link}>
                <h3>{mediumArticle.title}</h3>
                <div  className="about-card" dangerouslySetInnerHTML={{ __html: mediumArticle.description }} />
                <br/><br/>
              </div>
          })
        }
        setMediumFeedDetails({
          articles: mediumArticles,
          pageLoading: false
        })
      }

      fetchMediumFeed()
    },[isParentLoading])
    
    if (mediumFeedDetails.pageLoading || 
        mediumFeedDetails.articles.length == null || 
        mediumFeedDetails.articles.length == 0
    ) {
      return null;
    } else {
      <h1>Our Blog</h1>
      return (
        <div>
          <h1>Our Blog</h1>
          {mediumFeedDetails.articles}
        </div>
      );
    }
}

export default MediumFeed;
