import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert'
import { Spinner } from 'react-bootstrap'
import KeyWords from './KeyWords'
import DescriptionSpeech from './DescriptionSpeech'
import PodcastSentiment from './PodcastSentiment';

import logo from '../logo.svg';

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function getDescriptionWithEntities(description, entities) {
  let newDescription = ''
  let currIndex = 0
  for (let i=0; i<entities.length; i++) {
    newDescription += 
      description.substring(currIndex, entities[i].begin_offset) + `<a target="_blank" key=${i} href=${entities[i].wikipedia_link}>${entities[i].text}</a>`
    currIndex = entities[i].end_offset
  }
  newDescription += description.substring(currIndex, description.length-1)
  return newDescription
}

function AiDescription({ podcastEpisodeId, isParentLoading, podcastType, defaultDescription }) {
  const [aiDescriptionDetails, setAiDescriptionDetails] = useState({
    aiDescription: null,
    pageLoading: false,
    aiDescriptionEntities: []
  });

  useEffect(() => {
    if (isParentLoading) {
      return;
    }
    setAiDescriptionDetails({
      ...aiDescriptionDetails, pageLoading: true
    })
    const fetchAiDescription = async () => {
      let aiDescriptionUrl = new URL(`/public/api/ai/description/${podcastType}/${podcastEpisodeId}`, PRANKER_SERVER_URL);
      let aiDescriptionResponse = await fetch(aiDescriptionUrl)
        .then(
          (response) => {
            if (!response.ok) {
              throw new Error(`This is an HTTP error: The status is ${response.status}`);
            }
            return response.text();
          }).catch((err) => {
            console.log(err.message);
          })
      
      let descriptionEntitiesUrl = new URL(`/public/api/entities/podcast/${podcastType}/${podcastEpisodeId}`, PRANKER_SERVER_URL);
      let descriptionEntitiesResponse = await fetch(descriptionEntitiesUrl)
        .then(
          (response) => {
            if (!response.ok) {
              throw new Error(`This is an HTTP error: The status is ${response.status}`);
            }
            return response.json();
          }).catch((err) => {
            console.log(err.message);
            return []
          })

      setAiDescriptionDetails({
        aiDescription: aiDescriptionResponse,
        aiDescriptionEntities: descriptionEntitiesResponse.entities,
        pageLoading: false
      })
    }

    fetchAiDescription()
  }, [isParentLoading])

  const spinner = <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>

  if (isParentLoading) {
    return null
  } if (aiDescriptionDetails.pageLoading) {
    return <div>{spinner}</div>
  } else if (aiDescriptionDetails.aiDescription == null || aiDescriptionDetails.aiDescription.length < 5) {
    return (
      <div style={{ padding: '20px', textAlign: 'center', margin: '0 auto', width: '80%' }}>
        <div>
          {/* We use regular description because this is not generated by pod rank */}
          <b style={{ marginBottom: "20px" }}>Description</b>
        </div>
        <div style={{ maxHeight: 100, overflow: 'auto', padding: '20px' }}><p>{defaultDescription}</p></div>
      </div>
    )
  } else {
    return (
      <div style={{ padding: '20px', textAlign: 'center', margin: '0 auto', width: '80%' }}>
        <PodcastSentiment
          podcastEpisodeId={podcastEpisodeId}
          isParentLoading={aiDescriptionDetails.pageLoading}
          podcastType={podcastType}
        />
        <KeyWords
          podcastEpisodeId={podcastEpisodeId}
          isParentLoading={aiDescriptionDetails.pageLoading}
          podcastType={podcastType} />
        <DescriptionSpeech
          podcastEpisodeId={podcastEpisodeId}
          isParentLoading={aiDescriptionDetails.pageLoading}
          podcastType={podcastType} />
        <div>
          {/* we add our logo because we generate this description */}
          <img src={logo} alt="Pod Rank Card logo" className="logo2" />
          <b style={{ marginBottom: "20px" }}>Description</b>
        </div>
        <br />
        <div dangerouslySetInnerHTML={{ __html: getDescriptionWithEntities(aiDescriptionDetails.aiDescription, aiDescriptionDetails.aiDescriptionEntities) }} />
      </div>
    );
  }
}

export default AiDescription;
