import React, { useState, useEffect} from 'react';
import PodcastCard from './PodcastCard'
import { CardGroup, Spinner } from 'react-bootstrap';

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function RecommendationList({authToken, podcastEpisodeId, podcastType, loadPodcastPage, isParentLoading}) {
  const [recommendationDetails, setRecommendationDetails] = useState({
    podcastEpisodes: [],
    loading: true
  });

  useEffect(() => {
    if (isParentLoading) {
      return;
    }
    setRecommendationDetails({...recommendationDetails, loading: true})
    const fetchSimilarPodcasts = async () => {
      try {
        let similarPodcastsUrl = new URL(`public/api/ai/similar-podcasts/${podcastType}/${podcastEpisodeId}`, PRANKER_SERVER_URL);
        let headers = {'Content-Type': 'application/json'}
        let similarPodcastsResponse = await fetch(similarPodcastsUrl, {
          headers: headers
        })
          .then(
            (response) => {
              if (!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`);
              }
              return response.json();
          })
          setRecommendationDetails({podcastEpisodes: similarPodcastsResponse.podcastEpisodes, loading: false})
      } catch (e) {
          console.log(e.message)
          setRecommendationDetails({
            podcastEpisodes: [],
            loading: false
          })
      }
    }

    fetchSimilarPodcasts()
  },[isParentLoading])

  if (recommendationDetails.loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  } else if (recommendationDetails.podcastEpisodes.length == 0) {
    console.log(`No recommendations could be generated for ${podcastEpisodeId}`)
    return null
  } else {
    let podcastCards = recommendationDetails.podcastEpisodes.map(podcast => (
        <PodcastCard
          key={podcast.id}
          podcast_id={podcast.id}
          podcast_type = {podcast.pod_type}
          auth_token={authToken}
          episode_title={podcast.episode_title} 
          episode_series={podcast.episode_series}
          image_url={podcast.image_url}
          loadPodcastPage={loadPodcastPage}
        />
      ));
    
    return (
        <div>
          <h4>If you enjoyed this podcast, check our recommendations below for some you may enjoy.</h4>
          <CardGroup className='centered-content'> {podcastCards} </CardGroup>
        </div>
    );
  }
}

export default RecommendationList;
