import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import logo from '../logo.svg';
import { Link } from "react-router-dom";
import './styles/info-carousel.css';


const { REACT_APP_PRANKER_SERVER } = process.env;

function InfoCarousel({authToken, isParentLoading}) {
    
    if (isParentLoading) {
      return null;
    } else {
      let loginPrompt = null
      if (authToken == null) {
        loginPrompt = (
          <Carousel.Item interval={2000}>
            <div style={{ backgroundColor: 'black', height: '200px'}}>
              <img src={logo} alt="Pod Rank Card logo"/>
            </div>
            <Carousel.Caption>
              <h3><Link to="/login">Log in</Link> For More Insights</h3>
            </Carousel.Caption>
          </Carousel.Item>
        )
      }
      return (
        <Carousel className='info-carousel'>
          <Carousel.Item interval={3000}>
            <div style={{ backgroundColor: 'black', height: '200px'}}>
              <img src={logo} alt="Pod Rank Card logo"/>
            </div>
            <Carousel.Caption>
            <h3>Email <a href="mailto:contact@podrank.co">contact@podrank.co</a> to feature your podcast</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <div style={{ backgroundColor: 'black', height: '200px'}}>
              <img src={logo} alt="Pod Rank Card logo"/>
            </div>
            <Carousel.Caption>
            <h3>Get <a href='https://www.icloud.com/shortcuts/3ca85b1d01ac476f94071423af6f9920'  target="_blank">
              the approved iOS Shortcut for Pod Rank
              </a></h3>
            </Carousel.Caption>
          </Carousel.Item>
          {loginPrompt}
        </Carousel>
      );
    }
}

export default InfoCarousel;
