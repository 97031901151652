import React, { useState, useEffect} from 'react';
import PodcastCard from './PodcastCard'
import { CardGroup, Spinner } from 'react-bootstrap';

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function PodcastList({type, authToken, loadPodcastPage, isParentLoading}) {
  const [podcastListDetails, setPodcastListDetails] = useState({
    podcastEpisodes: [],
    loading: true
  });

  useEffect(() => {
    if (isParentLoading) {
      return;
    }
    setPodcastListDetails({...podcastListDetails, loading: true})
    const fetchPodcasts = async () => {
      try {
        let podcastsUrl = new URL(`public/api/podcasts/${type}`, PRANKER_SERVER_URL);
        let headers = {'Content-Type': 'application/json'}
        let podcastListResponse = await fetch(podcastsUrl, {
          headers: headers
        })
          .then(
            (response) => {
              if (!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`);
              }
              return response.json();
          })
          setPodcastListDetails({podcastEpisodes: podcastListResponse.podcastEpisodes, loading: false})
      } catch (e) {
          console.log(e.message)
          setPodcastListDetails({
            podcastEpisodes: [],
            loading: false
          })
      }
    }

    fetchPodcasts()
  },[isParentLoading])

  if (podcastListDetails.loading) {
    return null;
  } else if (podcastListDetails.podcastEpisodes.length == 0) {
    console.log(`No podcasts could be loaded for ${type}`)
    return null
  } else {
    let podcastCards = podcastListDetails.podcastEpisodes.map(podcast => (
        <PodcastCard
          key={podcast.id}
          podcast_id={podcast.id}
          podcast_type = {podcast.pod_type}
          auth_token={authToken}
          episode_title={podcast.episode_title} 
          episode_series={podcast.episode_series}
          image_url={podcast.image_url}
          // function we pass to allow podcast page to load from the child component
          // see where it's created and invoked for more info(state)
          loadPodcastPage={loadPodcastPage}
        />
      ));
    let podcastHeading = null;
    if (type == 'mruwd') {
      podcastHeading = "New to Pod Rank"
    } else if (type == 'trwd') {
      podcastHeading = "Top Ranked on Pod Rank"
    }
    
    return (
        <div>
          <h3>{podcastHeading}</h3>
          <CardGroup className='centered-content'> {podcastCards} </CardGroup>
        </div>
    );
  }
}

export default PodcastList;
