import React, { useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom'
import StripePage from './StripePage'

const { REACT_APP_PRANKER_SERVER } = process.env;

function Profile() {
    const location = useLocation()
    const { pranker_token } = location.state
    const [user, setUser] = useState(null);


      useEffect(() => {
        document.title = "Profile";
        fetch(REACT_APP_PRANKER_SERVER + "/api/prankers",
        {
          headers: {
            Authorization: "Bearer "+ pranker_token,
          }
        })
          .then(res => res.json())
          .then(
            (result) => {
                setUser(result);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
          )
        // setMounted(true)
      },[pranker_token])


    return (
        <div>
            {
                user == null ? 
                    <p>Error loading user profile</p> :
                    <div>
                        <h1>Hello {user.username}</h1>
                        <p>Your phoneNum is: </p> <input value={user.phoneNum} type = "text" readOnly={true}/>
                        <p>Your email is: </p> <input value={user.email} type = "text" readOnly={true}/>
                        <p>You have generated {user.numClouds ?? 0 } Pod Rank clouds</p>

                        <StripePage pranker_token={pranker_token} user={user}/>
                    </div>
            }
        </div>
    );
}

export default Profile;
