import React, { useState, useEffect} from 'react';
import { Button } from 'react-bootstrap';
import { useAlert } from 'react-alert'
import { useNavigate } from "react-router";

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

export default function Wordcloud({podcastType, authToken, episodeId, isParentLoading}) {
    const alert = useAlert()
    const navigate = useNavigate()
    const [wordcloud, setWordcloud] = useState({
        base64String: ''
    })
    
    useEffect(() => {
      
    },[isParentLoading])

    const getWordCloud = () => {
        if (authToken == null ){
            alert.error('You must log in to generate word cloud')
        } else {
            let getWordcloudRequest = new URL(`private/api/wordcloud/${podcastType}/${episodeId}`, PRANKER_SERVER_URL);
            fetch(getWordcloudRequest, {
                headers: {
                    Authorization: "Bearer "+ authToken,
                }}
            ).then((response) => {
                if (!response.ok) {
                    throw new Error(`This is an HTTP error: The status is ${response.status}`);
                }
                return response.text();
            }).then((result) => {
                setWordcloud({
                    base64String: "data:image/png;base64," + result
                })
                alert.success('successfully generated word cloud')
            }).catch((err) => {
                console.log(err.message);
                alert.error('error generating word cloud: please try again later')
            });
        }
    }

    if(authToken == null) {
        return <div>
            <Button variant="secondary" onClick={() => navigate("/login")}>
                Get Wordcloud
            </Button>
        </div>;
    }
    return (
        <div>
            <Button variant="secondary" onClick={getWordCloud}>Get Wordcloud</Button>
            <br/>
            <img src = {wordcloud.base64String}/>
        </div>
    );
}
