
import React, { useState, useEffect } from 'react';
import { Form, Button, Stack, CardGroup, FloatingLabel } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap'
import PodcastTypes from '../types/podcast_types'
import PodcastCard from './PodcastCard';
import PodcastList from './PodcastList'
import InfoCarousel from './InfoCarousel';
import { useNavigate } from "react-router";
import { getPodcastUrlFromSearchQuery } from '../utils/podcast_episode_utils'
const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function FunctionalLanding(props) {
  document.title = "Pod Rank";
  const [podrankToken, setPodrankToken] = useState(props.token);
  const [query, setQuery] = useState("");
  const [submissionCount, setSubmissionCount] = useState(0);
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false)
  const [podrankPodcastEpisodes, setPodrankPodcastEpisodes] = useState(null);
  const [spotifyPodcastEpisodes, setSpotifyPodcastEpisodes] = useState(null);
  const [youtubePodcastEpisodes, setYoutubePodcastEpisodes] = useState(null);
  const [searchResultsTitle, setSearchResultsTitle] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState(null);
  const [mostRecentlyRatedPodcastEpisodes, setMostRecentlyRatedPodcastEpisodes] = useState([]);

  // let security_level = auth_token === null ? "public" : "private" ;

  // We assume that the podcast will match the type we check for
  const getImageUrlFromSearchResponse = (podcast_type, podcast) => {
    switch (podcast_type) {
      case PodcastTypes.SPOTIFY:
        return podcast.images.length > 0 ? podcast.images[0].url : null
      case PodcastTypes.YOUTUBE:
        return podcast.snippet.thumbnails.high.url
      case "pod_rank":
        return podcast.image_url
      default:
        console.log(`Received unexpected podcast type when getting image url for podcast card: ${podcast_type}.`);
        return ""
    }
  }

  const getSearchUrl = (searchQuery) => {
    let podRankSearchUrl = new URL("public/api/podcasts/search", PRANKER_SERVER_URL);
    podRankSearchUrl.searchParams.append("query", searchQuery);
    return podRankSearchUrl.href
  }

  useEffect(() => {
    if (submissionCount > 0) {
      handleSubmit()
    }
    return;
  }, [submissionCount])
  // the submissionCount getting updated invokes the useEffect function

  const handleSubmit = () => {
    setPageLoading(true)
    let podcastUrl = getPodcastUrlFromSearchQuery(query)
    if (podcastUrl != null) {
      navigate(podcastUrl, { state: { authToken: podrankToken } });
      return;
    }

    // e.preventDefault()
    fetch(getSearchUrl(query))
      .then(
        (response) => {
          setPageLoading(false)
          if (!response.ok) {
            throw new Error(`This is an HTTP error: The status is ${response.status}`);
          }
          return response.json();
        })
      .then((response) => {
        setPodrankPodcastEpisodes(response.podrankPodcastEpisodes)
        setSpotifyPodcastEpisodes(response.spotifyPodcastEpisodes)
        setYoutubePodcastEpisodes(response.youtubePodcastEpisodes)
        if (query != "") {
          setSearchResultsTitle(`Search results for: ${query}`)
        } else {
          setSearchResultsTitle('Top Ranked Podcast Episodes')
        }
      })
      .catch((err) => {
        console.log(err.message);
        setPageLoading(false)
      });
  }

  let spotify_podcast_episode_cards = null;
  let podrank_podcast_episode_cards = null;
  let youtube_podcast_episode_cards = null;

  if (spotifyPodcastEpisodes != null) {
    spotify_podcast_episode_cards = spotifyPodcastEpisodes.map(podcast => (
      <PodcastCard
        key={podcast.id}
        podcast_id={podcast.id}
        podcast_type="spotify"
        auth_token={podrankToken}
        episode_title={podcast.name}
        episode_series=""
        image_url={getImageUrlFromSearchResponse("spotify", podcast)}
        should_reload={false}
      />
    ))
  }
  if (podrankPodcastEpisodes != null) {
    podrank_podcast_episode_cards = podrankPodcastEpisodes.map(podcast => (
      <PodcastCard
        key={podcast.id}
        podcast_id={podcast.id}
        podcast_type={podcast.pod_type}
        auth_token={podrankToken}
        episode_title={podcast.episode_title}
        episode_series={podcast.episode_series}
        image_url={getImageUrlFromSearchResponse("pod_rank", podcast)}
        should_reload={false}
      />
    ))
  }
  if (youtubePodcastEpisodes != null) {
    youtube_podcast_episode_cards = youtubePodcastEpisodes.map(podcast => (
      <PodcastCard
        key={podcast.id.videoId}
        podcast_id={podcast.id.videoId}
        podcast_type="youtube"
        auth_token={podrankToken}
        episode_title={podcast.snippet.title}
        episode_series={podcast.snippet.channelTitle}
        image_url={getImageUrlFromSearchResponse("youtube", podcast)}
        should_reload={false}
      />
    ))
  }
  let spinningLoader = null
  if (pageLoading) {
    spinningLoader =
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
  }
  return (
    <div>
      <Form onSubmit={(e) => { e.preventDefault(); setSubmissionCount((c) => c + 1) }}>
        <Stack direction="vertical">
          <div className="p-2" >
            <Form.Group className="centered-stars">
              <FloatingLabel className="search-box" controlId="floatingInput" label="Find your next podcast">
                <Form.Control type="text" placeholder="Find your next podcast" onChange={event => setQuery(event.target.value)} />
              </FloatingLabel>
            </Form.Group>
          </div>
          <div className="p-2">
            <Button disabled={pageLoading} variant="primary" type="submit">
              Search
            </Button>
          </div>
        </Stack>
      </Form>
      {spinningLoader}
      <h3>{searchResultsTitle}</h3>

      <CardGroup className='centered-content'> {youtube_podcast_episode_cards} </CardGroup>
      <CardGroup className='centered-content'> {spotify_podcast_episode_cards} </CardGroup>
      <CardGroup className='centered-content'> {podrank_podcast_episode_cards} </CardGroup>

      <PodcastList
        type="trwd"
        authToken={podrankToken}
        loadPodcastPage={null}
        isParentLoading={pageLoading}
      />

      <PodcastList
        type="mruwd"
        authToken={podrankToken}
        loadPodcastPage={null}
        isParentLoading={pageLoading}
      />
      <br />
      <InfoCarousel
        authToken={podrankToken} 
      />
      <br />
    </div>
  );
}

export default FunctionalLanding;
