import React, { useState, useEffect} from 'react';
import { useAlert } from 'react-alert'

import Badge from 'react-bootstrap/Badge';

const { REACT_APP_PRANKER_SERVER } = process.env;
const PRANKER_SERVER_URL = new URL(REACT_APP_PRANKER_SERVER);

function PodcastSentiment({podcastEpisodeId, isParentLoading, podcastType}) {
    const alert = useAlert()
    const [podcastSentimentDetails, setPodcastSentimentDetails] = useState({
      sentimentType: null,
      strength: 0,
      pageLoading: false
    });

    const getBadgeForSentimentType = (sentimentType, score)  => {
      let displayableScore = (score*100).toFixed(0)
      if(sentimentType == "MIXED") {
        return <Badge bg="warning">mixed: {displayableScore}%</Badge>
      } else if(sentimentType == "NEUTRAL") {
        return <Badge bg="secondary">neutral: {displayableScore}%</Badge>
      } else if(sentimentType == "POSITIVE") {
        return <Badge bg="success">positive: {displayableScore}%</Badge>
      } else if(sentimentType == "NEGATIVE") {
        return <Badge bg="danger">negative: {displayableScore}%</Badge>
      }
      throw new Error(`Unexpected sentiment type received: ${sentimentType}`);
    }

    useEffect(() => {
      if (isParentLoading) {
        return;
      }
      setPodcastSentimentDetails({
        ...podcastSentimentDetails, pageLoading: true
      })
      const fetchPodcastSentiment = async () => {
        let podcastSentimentUrl = new URL(`/public/api/sentiment/podcast/${podcastType}/${podcastEpisodeId}`, PRANKER_SERVER_URL);
        // the response is currently a blob
        let podcastSentimentResponse = await fetch(podcastSentimentUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
          }
        })
          .then(
            (response) => {
              if (!response.ok) {
                throw new Error(`This is an HTTP error: The status is ${response.status}`);
              }
              return response.json();
          }).catch((err) => {
            return null;
          })
        if(podcastSentimentResponse == null) {
          setPodcastSentimentDetails({
            ...podcastSentimentDetails,
            pageLoading: false
          })
          return;
        }
        setPodcastSentimentDetails({
          sentimentType: podcastSentimentResponse.sentimentType,
          strength: podcastSentimentResponse.strength,
          pageLoading: false
        })
        return;
      }
      fetchPodcastSentiment()
    },[isParentLoading])
    
    try {

      if (podcastSentimentDetails.pageLoading || podcastSentimentDetails.sentimentType == null) {
        return null;
      } else {
        return (
          <div>
            {getBadgeForSentimentType(podcastSentimentDetails.sentimentType, podcastSentimentDetails.strength)}
          </div>
        );
      }
    } catch(excep){
      return null;
    }
}

export default PodcastSentiment;
