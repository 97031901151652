import './App.css';
import FunctionalLanding from './components/FunctionalLanding'
import Login from './components/Login';
import Register from "./components/Register"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import About from './components/About';
import Navigation from './components/Navigation';
import PodcastPage from './components/PodcastPage'
import Profile from './components/Profile'
import Footer from './components/Footer';
import Cookies from 'js-cookie';

function App() {

  const [token, setToken] = useState(Cookies.get('accessToken'));
  let cookieRefreshToken = Cookies.get('refreshToken')

  let homeComponent = null;
  homeComponent = <FunctionalLanding token={token}/>

  return (
    <div className="App" style={bodyStyle}>
      <div style={contentStyles}>
      <BrowserRouter>
        <Navigation token={token}/>
        <Routes>
          <Route path="/" element={homeComponent} />
          <Route path="/landing" element={<FunctionalLanding token={token}/>} />
          <Route path="/login" element={<Login setToken={setToken}/>} />
          <Route path="/register" element={<Register />} />
          <Route path="/about" element={<About />} />
          <Route path="/podcast/:podcastType/:episodeId" element={<PodcastPage authToken={token}/>} />
          <Route path="/podcast/:podcastType/:episodeId/:episodeSlug" element={<PodcastPage authToken={token}/>} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </BrowserRouter>

      </div>
      <Footer />
    </div>
  );
}

// Styling for the footer
const bodyStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
};

// Styling for the main content area (adjust padding as needed)
const contentStyles = {
  flex: 1,
  // padding: '20px',
};

export default App;
