import React from 'react';
import '../App.css';
import logo from '../logo.svg';
import { Button } from 'react-bootstrap';

const { REACT_APP_PRANKER_SERVER } = process.env;

export default function SuccessDisplay ({ pranker_token, customer_id }) {
  const getPortalSession = (event) => {
    event.preventDefault();
    fetch(
        REACT_APP_PRANKER_SERVER + `/api/create-portal-session`,
        {
            method: 'POST',
            headers: {
                Authorization: "Bearer "+ pranker_token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              customer_id: customer_id,
              session_id: "bs"
            })
        }
    )
    .then(res => res.json())
    .then(
        (res) => {
            window.open(res.checkout_url, "_self");
        },
        (error) => {
            console.log("error checking out, please try again later! " + error)
        }
    )
  }

  return (
  <section>
      <div className="product Box-root">
        <img src={logo} alt="Pod Rank logo"/>
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <Button variant="primary" onClick={getPortalSession}>Checkout</Button>
  </section>
  );
}
